<template>
  <div>
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>我的下级</span>
      </div>
    </div>
    <div class="userdata">
      <!-- <div>
        <span>等级：</span>
        <span>{{ userdata.gradeNme }}</span>
      </div> -->
      <div class="dsiox">
        <span>佣金比例：</span>
        <span class="commission">千分之{{ userdata.commission * 1000 }}</span>
      </div>
      <div class="dsiox">
        <span>推广人数：</span>
        <span class="invitationNumber">{{ userdata.invitationNumber }}</span>
      </div>
    </div>

    <van-divider />
    <van-tabs
      v-model="active"
      @change="changedata"
      title-active-color="#3399CC"
      color="#666666"
    >
      <van-tab title="邀请用户">
        <div v-show="!showkong">
          <div class="yqyy" v-for="item in datss" :key="item.userId">
            <div>
              <span>名称：</span>
              <span class="namens" v-if="!item.username == ''">{{
                item.username
              }}</span>
              <span class="namens" v-else>未实名</span>
            </div>
            <div class="matop">
              <span>等级：</span>
              <span class="gradeName">{{ item.gradeName }}</span>
            </div>
            <div class="matop">
              <span>手机号：</span>
              <span class="mobile">{{ item.mobile }}</span>
            </div>
            <div class="matop">
              <span>创建时间：</span>
              <span>{{ item.createTime }}</span>
            </div>
          </div>
        </div>
        <div class="inxx_nimg" v-show="showkong">
          <img src="@/assets/images/yqyh.png" alt="" />
        </div>
      </van-tab>
      <van-tab title="用户收益">
        <div v-show="!yonghus">
          <div class="yqyy" v-for="iigd in oinres" :key="iigd.userId">
            <div>
              <span>佣金收益：</span>
              <span class="namens">千分之{{ iigd.money * 1000 }}</span>
            </div>
            <div class="matop">
              <span>类型：</span>
              <span
                class="gradeName"
                v-if="iigd.category == 'agreementWithholdingCommission'"
                >代扣佣金
              </span>
              <span class="gradeName" v-else>佣金 </span>
            </div>
            <div class="matop">
              <span>创建时间：</span>
              <span>{{ iigd.createTime }}</span>
            </div>
          </div>
        </div>
        <div class="inxx_nimg" v-show="yonghus">
          <img src="@/assets/images/yhsy.png" alt="" />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import {
  getDistributionInfo,
  getSubDistributionList,
  getSubIncomeList,
} from "@/request/api";
export default {
  data() {
    return {
      active: "0",
      userdata: "",
      datss: [],
      oinres: [],
      pageNum: 1,
      yhsy: true,
      yqyh: true,
      showkong: false,
      yonghus: false,

      pageSize: 5,
    };
  },
  created() {
    this.getbutionInfo();
    this.butionList();
    this.comeList();
    document.addEventListener("scroll", this.getscroll);
  },
  methods: {
    getscroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离c
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        console.log(111);
        this.pageNum++;
        if (this.yqyh) {
          this.butionList();
        }
        if (this.yhsy) {
          this.comeList();
        }
      }
    },

    getbutionInfo() {
      getDistributionInfo().then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.userdata = res.data;
        }
      });
    },
    // 用户列表
    butionList() {
      getSubDistributionList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          if (res.data.length < 5) {
            console.log(1111);
            this.yqyh = false;
          }
          this.datss = [...this.datss, ...res.data];
          if (this.datss.length == 0) {
            this.showkong = true;
          } else {
            this.showkong = false;
          }
          console.log(this.datss);
        }
      });
    },

    // 用户收益
    comeList() {
      getSubIncomeList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          if (res.data.length < 5) {
            console.log(1111);
            this.yhsy = false;
          }
          this.oinres = [...this.oinres, ...res.data];
          if (this.oinres.length == 0) {
            this.yonghus = true;
          } else {
            this.yonghus = false;
          }
          console.log(this.oinres);
        }
      });
    },
    // 修改标签
    changedata(value) {
      console.log(value);
      this.showkong = false;
      this.yonghus = false;
      this.pageNum = 1;
      if (value == 0) {
        this.yqyh = true;
        this.datss = [];
        this.butionList();
      } else {
        this.yhsy = true;
        this.oinres = [];
        this.comeList();
      }
    },
    goback() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style lang="scss" scoped>
.userdata {
  padding-top: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 0.6rem;
  .dsiox {
    margin-top: 0.5rem;
    .invitationNumber {
      color: #1989fa;
      font-weight: 550;
    }
    .commission {
      color: #ff9900;
    }
  }
}
.yqyy {
  padding: 0.6rem;
  margin-bottom: 0.8rem;
  width: 90%;
  padding: 0.6rem;
  background: #f6f6f6;
  margin: 0 auto;
  margin-top: 0.6rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  .matop {
    margin-top: 0.2rem;
  }
  .namens {
    font-weight: 550;
  }
  .gradeName {
    color: #ff9900;
  }
  .mobile {
    color: #1989fa;
  }
}
.inxx_nimg {
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  margin-top: 9rem;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>